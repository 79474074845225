import React from 'react'

const AdminLayout = (props) => {
    return (
        <>
             <div className="header_spacer_top" style={{ paddingTop: '105px' }}></div>
            {props.children}
        </>
    )
}

export default AdminLayout
